import React from "react"

import { Helmet } from "react-helmet"

import Layout from "../components/Layout"

function HeadlinesPage() {
  return (
    <Layout>
      <Helmet>
        <title>Thesis* - Headlines</title>
      </Helmet>
      <h1 className="h1">Heading</h1>
      <h2 className="h2">Heading</h2>
      <h3 className="h3">Heading</h3>
      <h4 className="h4">Heading</h4>
      <h5 className="h5">Heading</h5>
      <h6 className="h6">Heading</h6>
    </Layout>
  )
}

export default HeadlinesPage
